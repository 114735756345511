import React from "react"
import { Link } from "gatsby"
import TransitionLink from "gatsby-plugin-transition-link"
import gsap from "gsap"

const transtiionDuration = 0.3

const exitAnimation = (node, id) => {
  let ele = document.getElementById(id)
  gsap.set(ele, { opacity: 0 })
  gsap.to(node, { duration: transtiionDuration, opacity: 0, ease: "none" })
  // console.log(node, ele)
  // ele.style.position = "fixed"
  // ele.style.top = "0"
  // ele.style.left = "0"
  // ele.style.width = "100%"
  // gsap.set(ele, { opacity: 0 })
}
const entryAnimation = (node, id) => {
  let ele = document.getElementById(id)
  gsap.to(ele, { duration: transtiionDuration, opacity: 1, ease: "none" })
  // gsap.to(node, 0.3, {
  //   opacity: 0,
  //   ease: "none",
  // })
  // gsap.to(ele, 0.3, {
  //   opacity: 1,
  //   ease: "none",
  //   onComplete: () => {
  //     gsap.to(window, {
  //       scrollTo: {
  //         y: 0,
  //       },
  //       duration: 0,
  //       delay: 0,
  //     })
  //     ele.style.position = ""
  //   },
  // })
}

/**
 *
 * TODO: ページ遷移
 * - https://tomiko0404.hatenablog.com/entry/2021/11/23/react-router-dom-routing
 * - https://github.com/pmndrs/react-spring/issues/448
 * - https://v5.reactrouter.com/web/example/animated-transitions
 * - https://www.gatsbyjs.com/plugins/gatsby-v2-plugin-page-transitions/
 */

// export default class Header extends React.Component {
const Header = () => {
  // render() {
  return (
    <header className="l-header">
      <div className="p-header">
        <div className="p-header__wrapper">
          <div className="p-header__menu">
            <button className="p-header__menu-btn">
              <span className="p-header__menu-btn-border" />
              <span className="p-header__menu-btn-border" />
            </button>
            <nav className="p-header__menu-nav">
              <ul className="p-header__menu-nav-list">
                <li
                  className="js-hover js-hover__sp-remove u-none__pc p-header__menu-nav-item"
                  data-hover-in-time="400"
                >
                  <Link
                    to="/"
                    scroll={false}
                    className="js-click__menu js-current__menu__home p-header__menu-nav-link"
                  >
                    <span className="p-header__menu-nav-span">HOME</span>
                  </Link>
                </li>
                <li
                  className="js-hover js-hover__sp-remove p-header__menu-nav-item p-header__menu-nav-item__none"
                  data-hover-in-time="400"
                >
                  <Link
                    to="/newsletter/"
                    scroll={false}
                    className="js-click__menu p-header__menu-nav-link"
                  >
                    <span className="p-header__menu-nav-span">NEWSLETTER</span>
                  </Link>
                </li>
                <li
                  className="js-hover js-hover__sp-remove p-header__menu-nav-item p-header__menu-nav-item__none"
                  data-hover-in-time="400"
                >
                  <Link
                    to="/contact/"
                    scroll={false}
                    className="js-click__menu p-header__menu-nav-link"
                  >
                    <span className="p-header__menu-nav-span">CONTACT</span>
                  </Link>
                </li>
                <li
                  className="js-hover js-hover__sp-remove p-header__menu-nav-item"
                  data-hover-in-time="400"
                >
                  {/* <TransitionLink
                    to="/vision/"
                    className="js-click__menu p-header__menu-nav-link"
                    exit={{
                      trigger: ({ node }) => exitAnimation(node, "vision"),
                      length: 1,
                    }}
                    entry={{
                      trigger: ({ node }) => entryAnimation(node, "vision"),
                    }}
                  >
                    <span className="p-header__menu-nav-span">OUR MISSION</span>
                  </TransitionLink> */}
                  <Link
                    to="/vision/"
                    scroll={false}
                    className="js-click__menu p-header__menu-nav-link"
                  >
                    <span className="p-header__menu-nav-span">OUR MISSION</span>
                  </Link>
                </li>
                <li
                  className="js-hover js-hover__sp-remove p-header__menu-nav-item"
                  data-hover-in-time="400"
                >
                  {/* <TransitionLink
                    to="/service/"
                    className="js-click__menu p-header__menu-nav-link"
                    exit={{
                      trigger: ({ node }) => exitAnimation(node, "service"),
                      length: 1,
                    }}
                    entry={{
                      trigger: ({ node }) => entryAnimation(node, "service"),
                    }}
                  >
                    <span className="p-header__menu-nav-span">SERVICE</span>
                  </TransitionLink> */}
                  <Link
                    to="/service/"
                    scroll={false}
                    className="js-click__menu p-header__menu-nav-link"
                  >
                    <span className="p-header__menu-nav-span">SERVICE</span>
                  </Link>
                </li>
                <li
                  className="js-hover js-hover__sp-remove p-header__menu-nav-item__parent p-header__menu-nav-item"
                  data-hover-in-time="400"
                >
                  {/* <TransitionLink
                    to="/careers/"
                    className="js-hover js-hover__sp-remove js-click__menu p-header__menu-nav-link"
                    exit={{
                      trigger: ({ node }) => exitAnimation(node, "careers"),
                      length: 1,
                    }}
                    entry={{
                      trigger: ({ node }) => entryAnimation(node, "careers"),
                      length: 1,
                    }}
                  >
                    <span className="p-header__menu-nav-span">CAREERS</span>
                  </TransitionLink> */}
                  <Link
                    to="/careers/"
                    scroll={false}
                    className="js-hover js-hover__sp-remove js-click__menu p-header__menu-nav-link"
                  >
                    <span className="p-header__menu-nav-span">CAREERS</span>
                  </Link>
                  <div className="p-header__menu-nav-item__child">
                    <ul className="p-header__menu-nav-item__child-list">
                      <li className="p-header__menu-nav-item__child-item">
                        {/* <TransitionLink
                          to="/careers/farm/"
                          data-hover-in-time="400"
                          className="js-hover js-hover__sp-remove js-click__menu js-click__menu-child p-header__menu-nav-item__child-link"
                          exit={{
                            trigger: ({ node }) =>
                              exitAnimation(node, "careers"),
                            length: 1,
                          }}
                          entry={{
                            trigger: ({ node }) =>
                              entryAnimation(node, "careers"),
                          }}
                        >
                          <span className="p-header__menu-nav-item__child-span">
                            THE FARM
                          </span>
                        </TransitionLink> */}
                        <Link
                          to="/careers/farm/"
                          data-hover-in-time="400"
                          scroll={false}
                          className="js-hover js-hover__sp-remove js-click__menu js-click__menu-child p-header__menu-nav-item__child-link"
                        >
                          <span className="p-header__menu-nav-item__child-span">
                            THE FARM
                          </span>
                        </Link>
                      </li>
                      <li className="p-header__menu-nav-item__child-item">
                        {/* <TransitionLink
                          to="/careers/restaurant/"
                          data-hover-in-time="400"
                          className="js-hover js-hover__sp-remove js-click__menu js-click__menu-child p-header__menu-nav-item__child-link"
                          exit={{
                            trigger: ({ node }) =>
                              exitAnimation(node, "careers"),
                            length: 1,
                          }}
                          entry={{
                            trigger: ({ node }) =>
                              entryAnimation(node, "careers"),
                          }}
                        >
                          <span className="p-header__menu-nav-item__child-span">
                            RESTAURANT
                          </span>
                        </TransitionLink> */}
                        <Link
                          to="/careers/restaurant/"
                          data-hover-in-time="400"
                          scroll={false}
                          className="js-hover js-hover__sp-remove js-click__menu js-click__menu-child p-header__menu-nav-item__child-link"
                        >
                          <span className="p-header__menu-nav-item__child-span">
                            RESTAURANT
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  className="js-hover js-hover__sp-remove p-header__menu-nav-item"
                  data-hover-in-time="400"
                >
                  {/* <TransitionLink
                    to="/media/"
                    data-hover-in-time="400"
                    className="js-click__menu p-header__menu-nav-link"
                    exit={{
                      trigger: ({ node }) => exitAnimation(node, "media"),
                      length: 1,
                    }}
                    entry={{
                      trigger: ({ node }) => entryAnimation(node, "media"),
                    }}
                  >
                    <span className="p-header__menu-nav-span">MEDIA</span>
                  </TransitionLink> */}
                  <Link
                    to="/media/"
                    data-hover-in-time="400"
                    scroll={false}
                    className="js-click__menu p-header__menu-nav-link"
                  >
                    <span className="p-header__menu-nav-span">MEDIA</span>
                  </Link>
                </li>
                <li
                  className="js-hover js-hover__sp-remove p-header__menu-nav-item"
                  data-hover-in-time="400"
                >
                  {/* <TransitionLink
                    to="/openness/"
                    data-hover-in-time="400"
                    className="js-click__menu p-header__menu-nav-link"
                    exit={{
                      trigger: ({ node }) => exitAnimation(node, "openness"),
                      length: 1,
                    }}
                    entry={{
                      trigger: ({ node }) => entryAnimation(node, "openness"),
                    }}
                  >
                    <span className="p-header__menu-nav-span">OPENNESS</span>
                  </TransitionLink> */}
                  <Link
                    to="/openness/"
                    data-hover-in-time="400"
                    scroll={false}
                    className="js-click__menu p-header__menu-nav-link"
                  >
                    <span className="p-header__menu-nav-span">OPENNESS</span>
                  </Link>
                </li>
              </ul>
              <ul className="p-header__menu-nav-list p-header__menu-nav-list__sub">
                <li
                  className="js-hover js-hover__sp-remove u-none__pc p-header__menu-nav-item"
                  data-hover-in-time="400"
                >
                  <Link
                    to="/company/"
                    scroll={false}
                    className="js-click__menu p-header__menu-nav-link"
                  >
                    <span className="p-header__menu-nav-span">COMPANY</span>
                  </Link>
                </li>
                <li
                  className="js-hover js-hover__sp-remove p-header__menu-nav-item"
                  data-hover-in-time="400"
                >
                  <Link
                    to="/news/"
                    scroll={false}
                    className="js-click__menu p-header__menu-nav-link"
                  >
                    <span className="p-header__menu-nav-span">
                      NEWS
                      <span className="p-header__menu-nav-span-min">&</span>
                      PRESS
                    </span>
                  </Link>
                </li>
                <li
                  className="js-hover js-hover__sp-remove p-header__menu-nav-item p-header__menu-nav-item__contact"
                  data-hover-in-time="400"
                >
                  <a
                    className="js-click__menu p-header__menu-nav-link p-header__menu-nav-link__contact"
                    href="https://crispsupport.zendesk.com/hc/ja/requests/new"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="p-header__menu-nav-span">CONTACT</span>
                    <span className="c-icon__arrow">
                      <span className="c-icon__arrow__origin c-icon__arrow__origin__z">
                        <svg
                          className="c-icon__arrow__blank"
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.27637 14.1761L14.1759 4.27665"
                            stroke="#EAE8E3"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M14.1757 12.827L14.1757 4.27647L5.62402 4.27527"
                            stroke="#EAE8E3"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      <span className="c-icon__arrow__dammy c-icon__arrow__dammy__z">
                        <svg
                          className="c-icon__arrow__blank"
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.27637 14.1761L14.1759 4.27665"
                            stroke="#EAE8E3"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M14.1757 12.827L14.1757 4.27647L5.62402 4.27527"
                            stroke="#EAE8E3"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="p-header__logo">
            <Link
              to={"/"}
              scroll={false}
              className="js-click__menu js-current__menu__home p-header__logo-link"
              aria-label="crisp-corporate"
            >
              <svg
                className="p-header__logo-link-svg"
                viewBox="0 0 56 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="p-header__logo-link-path"
                  d="M7.29794 15.28C9.85794 15.28 12.0979 14.02 13.2979 12.04L10.5379 10.44C9.93794 11.52 8.71795 12.16 7.29794 12.16C4.81794 12.16 3.21794 10.5 3.21794 8C3.21794 5.5 4.81794 3.84 7.29794 3.84C8.71795 3.84 9.91794 4.48 10.5379 5.56L13.2979 3.96C12.0779 1.98 9.83794 0.719997 7.29794 0.719997C3.07794 0.719997 0.0179443 3.88 0.0179443 8C0.0179443 12.12 3.07794 15.28 7.29794 15.28Z"
                  fill="#1F140F"
                />
                <path
                  className="p-header__logo-link-path"
                  d="M22.1576 15H25.5976L22.6176 9.86C24.1576 9.08 25.1976 7.52 25.1976 5.8C25.1976 3.14 23.0576 0.999998 20.3976 0.999998H14.7976V15H17.9976V10.4H19.5176L22.1576 15ZM17.9976 4H20.3976C21.2776 4 21.9976 4.78 21.9976 5.8C21.9976 6.82 21.2776 7.6 20.3976 7.6H17.9976V4Z"
                  fill="#1F140F"
                />
                <path
                  className="p-header__logo-link-path"
                  d="M27.1023 0.999998V15H30.3023V0.999998H27.1023Z"
                  fill="#1F140F"
                />
                <path
                  className="p-header__logo-link-path"
                  d="M37.4125 15.28C40.4525 15.28 42.6125 13.68 42.6125 10.98C42.6125 8.02 40.2325 7.28 38.1125 6.64C35.9325 5.98 35.5925 5.54 35.5925 4.9C35.5925 4.34 36.0925 3.84 37.0925 3.84C38.3725 3.84 39.0325 4.46 39.5125 5.46L42.2125 3.88C41.1925 1.82 39.3925 0.719997 37.0925 0.719997C34.6725 0.719997 32.3925 2.28 32.3925 4.98C32.3925 7.66 34.4325 8.66 36.5125 9.24C38.6125 9.82 39.4125 10.16 39.4125 11.02C39.4125 11.56 39.0325 12.16 37.5125 12.16C35.9325 12.16 35.0725 11.38 34.5725 10.22L31.8125 11.82C32.5925 13.88 34.4725 15.28 37.4125 15.28Z"
                  fill="#1F140F"
                />
                <path
                  className="p-header__logo-link-path"
                  d="M49.414 0.999998H44.114V15H47.314V10.6H49.414C52.174 10.6 54.314 8.46 54.314 5.8C54.314 3.14 52.174 0.999998 49.414 0.999998ZM49.414 7.6H47.314V4H49.414C50.394 4 51.114 4.78 51.114 5.8C51.114 6.82 50.394 7.6 49.414 7.6Z"
                  fill="#1F140F"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </header>
  )
}
// }

export default Header
