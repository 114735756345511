import React from "react"
import ArrowZ from "./ArrowZ"
import TransitionLink from "gatsby-plugin-transition-link"
import gsap from "gsap"
import { Link } from "gatsby"

const exitAnimation = (node, id) => {
  let ele = document.getElementById(id)
  gsap.set(ele, { opacity: 0 })
  gsap.to(node, { duration: 0.3, opacity: 0, ease: "none" })
}
const entryAnimation = (node, id) => {
  let ele = document.getElementById(id)
  gsap.to(ele, { duration: 0.3, opacity: 1, ease: "none" })
}

const Footer = ({}) => {
  const divStyle = {
    position: "absolute",
    left: "-5000px",
  }

  return (
    <footer className="l-footer">
      <div className="p-footer">
        <section className="p-footer__letter">
          <div className="p-footer__letter-wrapper">
            <div className="p-footer__letter-block">
              <h2 className="c-ttl__en p-footer__letter-block-ttl">
                NEWSLETTER
              </h2>
              <p className="c-txt__min p-footer__letter-block-txt">
                CRISPの最新情報をすぐに知りたい？ <br />
                OK、NEWSLETTERを用意しました。
              </p>
            </div>
            <div className="p-footer__letter-form" id="mc_embed_signup">
              <form
                className="validate"
                action="https://crisp.us20.list-manage.com/subscribe/post?u=f31e7d37d2b73d7d30d5e1924&amp;id=1a2305fe3f"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                target="_blank"
                noValidate
              >
                <div
                  className="p-footer__letter-form-wrapper"
                  id="mc_embed_signup_scroll"
                >
                  <label
                    className="p-footer__letter-form-label"
                    htmlFor="mce-EMAIL"
                  >
                    SUBSCRIBE
                  </label>
                  <input
                    className="p-footer__letter-form-input email"
                    id="mce-EMAIL"
                    type="email"
                    name="EMAIL"
                    placeholder="YOUR EMAIL"
                    required
                    defaultValue={""}
                  />
                  <div style={divStyle} aria-hidden="true">
                    <input
                      type="text"
                      name="b_f31e7d37d2b73d7d30d5e1924_1a2305fe3f"
                      tabIndex="-1"
                      defaultValue={""}
                    />
                  </div>
                  <div
                    className="js-hover c-btn__main clear"
                    data-hover-in-time="600"
                    data-hover-out-time="650"
                  >
                    <span className="c-btn__main__cover c-btn__main__cover__x">
                      <input
                        className="p-footer__letter-form-btn button"
                        id="mc-embedded-subscribe"
                        type="submit"
                        value="SUBSCRIBE"
                        name="subscribe"
                      />
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
        <div className="p-footer__wrapper">
          <div className="p-footer__menu">
            <nav className="p-footer__menu-nav">
              <ul className="p-footer__menu-nav-list">
                <li className="p-footer__menu-nav-item">
                  {/* <TransitionLink
                    to="/"
                    data-hover-in-time="400"
                    className="js-hover js-hover__sp-remove js-click__menu js-current__menu__home p-footer__menu-nav-link"
                    exit={{
                      trigger: ({ node }) => exitAnimation(node, "home"),
                      length: 1,
                    }}
                    entry={{
                      trigger: ({ node }) => entryAnimation(node, "home"),
                    }}
                  >
                    <span className="p-footer__menu-nav-span">HOME</span>
                  </TransitionLink> */}
                  <Link
                    to="/"
                    data-hover-in-time="400"
                    className="js-hover js-hover__sp-remove js-click__menu js-current__menu__home p-footer__menu-nav-link"
                  >
                    <span className="p-footer__menu-nav-span">HOME</span>
                  </Link>
                </li>
                <li className="p-footer__menu-nav-item">
                  {/* <TransitionLink
                    to="/vision/"
                    data-hover-in-time="400"
                    className="js-hover js-hover__sp-remove js-click__menu p-footer__menu-nav-link"
                    exit={{
                      trigger: ({ node }) => exitAnimation(node, "vision"),
                      length: 1,
                    }}
                    entry={{
                      trigger: ({ node }) => entryAnimation(node, "vision"),
                    }}
                  >
                    <span className="p-footer__menu-nav-span">OUR MISSION</span>
                  </TransitionLink> */}
                  <Link
                    to="/vision/"
                    data-hover-in-time="400"
                    className="js-hover js-hover__sp-remove js-click__menu p-footer__menu-nav-link"
                  >
                    <span className="p-footer__menu-nav-span">OUR MISSION</span>
                  </Link>
                </li>
                <li className="p-footer__menu-nav-item">
                  {/* <TransitionLink
                    to="/service/"
                    data-hover-in-time="400"
                    className="js-hover js-hover__sp-remove js-click__menu p-footer__menu-nav-link"
                    exit={{
                      trigger: ({ node }) => exitAnimation(node, "service"),
                      length: 1,
                    }}
                    entry={{
                      trigger: ({ node }) => entryAnimation(node, "service"),
                    }}
                  >
                    <span className="p-footer__menu-nav-span">SERVICE</span>
                  </TransitionLink> */}
                  <Link
                    to="/service/"
                    data-hover-in-time="400"
                    className="js-hover js-hover__sp-remove js-click__menu p-footer__menu-nav-link"
                  >
                    <span className="p-footer__menu-nav-span">SERVICE</span>
                  </Link>
                </li>
                <li className="p-footer__menu-nav-item">
                  {/* <TransitionLink
                    to="/careers/"
                    data-hover-in-time="400"
                    className="js-hover js-hover__sp-remove js-click__menu p-footer__menu-nav-link"
                    exit={{
                      trigger: ({ node }) => exitAnimation(node, "careers"),
                      length: 1,
                    }}
                    entry={{
                      trigger: ({ node }) => entryAnimation(node, "careers"),
                    }}
                  >
                    <span className="p-footer__menu-nav-span">CAREERS</span>
                  </TransitionLink> */}
                  <Link
                    to="/careers/"
                    data-hover-in-time="400"
                    className="js-hover js-hover__sp-remove js-click__menu p-footer__menu-nav-link"
                  >
                    <span className="p-footer__menu-nav-span">CAREERS</span>
                  </Link>
                </li>
                <li className="p-footer__menu-nav-item">
                  {/* <TransitionLink
                    to="/media/"
                    data-hover-in-time="400"
                    className="js-hover js-hover__sp-remove js-click__menu p-footer__menu-nav-link"
                    exit={{
                      trigger: ({ node }) => exitAnimation(node, "media"),
                      length: 1,
                    }}
                    entry={{
                      trigger: ({ node }) => entryAnimation(node, "media"),
                    }}
                  >
                    <span className="p-footer__menu-nav-span">MEDIA</span>
                  </TransitionLink> */}
                  <Link
                    to="/media/"
                    data-hover-in-time="400"
                    className="js-hover js-hover__sp-remove js-click__menu p-footer__menu-nav-link"
                  >
                    <span className="p-footer__menu-nav-span">MEDIA</span>
                  </Link>
                </li>
                <li className="p-footer__menu-nav-item">
                  {/* <TransitionLink
                    to="/openness/"
                    data-hover-in-time="400"
                    className="js-hover js-hover__sp-remove js-click__menu p-footer__menu-nav-link"
                    exit={{
                      trigger: ({ node }) => exitAnimation(node, "openness"),
                      length: 1,
                    }}
                    entry={{
                      trigger: ({ node }) => entryAnimation(node, "openness"),
                    }}
                  >
                    <span className="p-footer__menu-nav-span">OPENNESS</span>
                  </TransitionLink> */}
                  <Link
                    to="/openness/"
                    data-hover-in-time="400"
                    className="js-hover js-hover__sp-remove js-click__menu p-footer__menu-nav-link"
                  >
                    <span className="p-footer__menu-nav-span">OPENNESS</span>
                  </Link>
                </li>
              </ul>
              <ul className="p-footer__menu-nav-list">
                <li className="p-footer__menu-nav-item">
                  {/* <TransitionLink
                    to="/company/"
                    data-hover-in-time="400"
                    className="js-hover js-hover__sp-remove js-click__menu p-footer__menu-nav-link"
                    exit={{
                      trigger: ({ node }) => exitAnimation(node, "company"),
                      length: 1,
                    }}
                    entry={{
                      trigger: ({ node }) => entryAnimation(node, "company"),
                    }}
                  >
                    <span className="p-footer__menu-nav-span">COMPANY</span>
                  </TransitionLink> */}
                  <Link
                    to="/company/"
                    data-hover-in-time="400"
                    className="js-hover js-hover__sp-remove js-click__menu p-footer__menu-nav-link"
                  >
                    <span className="p-footer__menu-nav-span">COMPANY</span>
                  </Link>
                </li>
                <li className="p-footer__menu-nav-item">
                  {/* <TransitionLink
                    to="/news/"
                    data-hover-in-time="400"
                    className="js-hover js-hover__sp-remove js-click__menu p-footer__menu-nav-link"
                    exit={{
                      trigger: ({ node }) => exitAnimation(node, "news"),
                      length: 1,
                    }}
                    entry={{
                      trigger: ({ node }) => entryAnimation(node, "news"),
                    }}
                  >
                    <span className="p-footer__menu-nav-span">
                      NEWS<span className="p-footer__menu-nav-span-min">&</span>
                      PRESS
                    </span>
                  </TransitionLink> */}
                  <Link
                    to="/news/"
                    data-hover-in-time="400"
                    className="js-hover js-hover__sp-remove js-click__menu p-footer__menu-nav-link"
                  >
                    <span className="p-footer__menu-nav-span">
                      NEWS<span className="p-footer__menu-nav-span-min">&</span>
                      PRESS
                    </span>
                  </Link>
                </li>
                <li className="p-footer__menu-nav-item">
                  <a
                    className="js-hover js-hover__sp-remove js-click__menu p-footer__menu-nav-link"
                    data-hover-in-time="400"
                    href="https://r.crisp.co.jp/inquiry"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="p-footer__menu-nav-span">CONTACT</span>
                    <ArrowZ />
                  </a>
                </li>
                <li className="p-footer__min">
                  <ul className="p-footer__min-list">
                    <li className="p-footer__min-item">
                      <a
                        className="js-hover js-hover__sp-remove p-footer__min-link"
                        data-hover-in-time="400"
                        href="https://r.crisp.co.jp/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="p-footer__min-span">
                          PRIVACY POLICY
                        </span>
                        <ArrowZ />
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
          <div className="p-footer__outside">
            <ul className="p-footer__outside-list">
              <li className="p-footer__outside-item">
                <a
                  className="js-hover js-hover__sp-remove p-footer__outside-link"
                  data-hover-in-time="600"
                  href="https://www.crisp.co.jp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="p-footer__outside-span">
                    <span className="c-link__main-inner">
                      CRISP SALAD WORKS
                    </span>
                    <ArrowZ />
                  </span>
                </a>
              </li>
              {/* <li className="p-footer__outside-item">
                <a className="js-hover js-hover__sp-remove p-footer__outside-link"
                   data-hover-in-time="600"
                   href="https://pltfrm.jp/"
                   target="_blank"
                   rel="noopener noreferrer">
                  <span className="p-footer__outside-span">
                    <span className="c-link__main-inner">
                      CRISP PLATFORM
                    </span>
                    <ArrowZ />
                  </span>
                </a>
              </li> */}
              <li className="p-footer__outside-item">
                <a
                  className="js-hover js-hover__sp-remove p-footer__outside-link"
                  data-hover-in-time="600"
                  href="https://r.crisp.co.jp/metrics"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="p-footer__outside-span">
                    <span className="c-link__main-inner">CRISP METRICS</span>
                    <ArrowZ />
                  </span>
                </a>
              </li>
              <li className="p-footer__min">
                <ul className="p-footer__min-list">
                  <li className="p-footer__min-item">
                    <a
                      className="js-hover js-hover__sp-remove p-footer__min-link"
                      data-hover-in-time="400"
                      href="https://www.instagram.com/crispsaladworks/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="p-footer__min-span">Instagram</span>
                    </a>
                  </li>
                  <li className="p-footer__min-item">
                    <a
                      className="js-hover js-hover__sp-remove p-footer__min-link"
                      data-hover-in-time="400"
                      href="https://twitter.com/crispsaladworks"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="p-footer__min-span">Twitter</span>
                    </a>
                  </li>
                  <li className="p-footer__min-item">
                    <a
                      className="js-hover js-hover__sp-remove p-footer__min-link"
                      data-hover-in-time="400"
                      href="https://www.facebook.com/crispsaladworks"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="p-footer__min-span">Facebook</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="p-footer__copyright">
          {/* <small className="p-footer__copyright-small">© 2021 CRISP</small> */}
          <small className="p-footer__copyright-small">
            © {new Date().getFullYear()} CRISP
          </small>
        </div>
      </div>
    </footer>
  )
}

export default Footer
