import React from "react"

const ArrowZ = ({ location, title, children }) => {
  return (
    <span className="c-icon__arrow">
    <span className="c-icon__arrow__origin c-icon__arrow__origin__z">
        <svg className="c-icon__arrow__blank"
             width="20"
             height="20"
             viewBox="0 0 20 20"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path className="c-icon__arrow-path"
                  d="M5.05103 14.9971L14.9505 5.09758"
                  stroke="#1F140F"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round" />
            <path className="c-icon__arrow-path"
                  d="M14.9504 13.6484V5.09788L6.39868 5.09668"
                  stroke="#1F140F"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round" />
        </svg>
    </span>
    <span className="c-icon__arrow__dammy c-icon__arrow__dammy__z">
        <svg className="c-icon__arrow__blank"
             width="20"
             height="20"
             viewBox="0 0 20 20"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path className="c-icon__arrow-path"
                  d="M5.05103 14.9971L14.9505 5.09758"
                  stroke="#1F140F"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round" />
            <path className="c-icon__arrow-path"
                  d="M14.9504 13.6484V5.09788L6.39868 5.09668"
                  stroke="#1F140F"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round" />
        </svg>
    </span>
</span>
  )
}

export default ArrowZ